import moment from "moment";
// FOR SAFARI/IOS: '2018/04/24 00:00:00' <- no dashes, no nanoseconds
// CHROME: accepts safari datetime strings,
// TODO this does not work well for non-safari/chrome need to evaluate the issue further
function convertToIOS(date) {
  // if date is null or undefined or empty string, return null
  if (!date || date === "") {
    return "";
  }
  if (typeof date !== "string") {
    date = date.toString();
  }
  if (date !== undefined) {
    date = date.replace(/-/g, "/");
    date = date.replace(/\.\d{6}/g, "");
  }
  return date;
}

function TableDateFormat(date, convertUTC = false) {
  if (date !== undefined) {
    return parseDate(date, convertUTC).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }
  return "";
}
function DateTime(date, convertUTC = false) {
  let datetime = undefined;
  if (date) {
    datetime = parseDate(date, convertUTC);
  } else {
    datetime = new Date();
  }
  return datetime.toISOString().replace("T", " ").replace("Z", "000");
}
function parseDateTime(date) {
  if (!date) {
    return moment().format("YYYY-MM-DD hh:mm:ss");
  }
  return moment(date).format("YYYY-MM-DD hh:mm:ss.SSS");
}

function FormatDate(date, convertUTC = false) {
  let datetime = undefined;
  if (date) {
    datetime = parseDate(date, convertUTC);
  } else {
    datetime = new Date();
  }
  return datetime.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

function FormatDateTime(date, convertUTC = false) {
  let datetime = undefined;
  if (date) {
    datetime = parseDate(date, convertUTC);
  } else {
    datetime = new Date();
  }
  return datetime.toLocaleDateString() + " " + datetime.toLocaleTimeString();
}

function parseDate(dateStr, convertUTC = false) {
  let date = new Date();
  if (dateStr) {
    if (typeof dateStr === "string") {
      /*if (dateStr.includes(":")) {
        //date and time string
        //format: 2021-08-31 00:00:00.000000
        let dateSplt = dateStr.split(" ");
        let time = dateSplt[1]?.split(":");
        dateSplt = dateSplt[0]?.split("-");

        date.setFullYear(dateSplt[0]);
        date.setMonth(dateSplt[1] - 1);
        date.setDate(dateSplt[2]);
        date.setHours(time[0]);
        date.setMinutes(time[1]);
        date.setSeconds(time[2].split(".")[0]);
      } else {
        //date format only
        //format: 2022-09-21
        let dateSplt = dateStr.split("-");
        date.setFullYear(dateSplt[0]);
        date.setMonth(dateSplt[1] - 1);
        date.setDate(dateSplt[2]);
      }*/
      date = new Date(dateStr);
    } else if (typeof dateStr === "object") {
      //checks to ensure value isn't already a date object
      date = dateStr;
    }
  }
  if (convertUTC) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  }

  return date;
}

function TimeOfDay() {
  const time = new Date().getHours();
  if (time < 12) {
    return "Good morning";
  }
  if (time >= 12 && time < 17) {
    return "Good afternoon";
  }
  if (time >= 17) {
    return "Good evening";
  }
  return "Hello";
}

export {
  DateTime,
  parseDateTime,
  FormatDate,
  FormatDateTime,
  TimeOfDay,
  convertToIOS,
  parseDate,
  TableDateFormat,
};
